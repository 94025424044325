<!--
 * @Author: DY
 * @Date: 2020-09-01 16:05:30
 * @LastEditors: 任赛赛 5595670+ren_sai_sai_sai@user.noreply.gitee.com
 * @LastEditTime: 2023-01-12 11:46:08
 * @Describe:
-->
<template>
    <div
        class="engineeringLifeCycle"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <header class="lifeCycleHeader">
            <div class="title">
                项目生命周期
            </div>
        </header>
        <div class="lifeCycleContent">
            <div class="box_t">
                <div class="box_t_l el_left">
                    <div class="basicEngineeringInfo boxBorder">
                        <div class="engineeringInfo el_left boxShadow">
                            <h4 class="header">
                                项目信息
                            </h4>
                            <ul>
                                <li>
                                    <p>项目编号：{{ essentialInfo.engineering_number }}</p>
                                </li>
                                <li>
                                    <p>建设单位：{{ essentialInfo.client_name }}</p>
                                </li>
                                <li>
                                    <p>项目名称：{{ essentialInfo.engineering_name }}</p>
                                </li>
                                <li>
                                    <p>项目位置：{{ essentialInfo.engineering_location }}</p>
                                </li>
                                <li>
                                    <p>项目类型：{{ essentialInfo.engineering_type_name }}</p>
                                    <p>开工时间：{{ essentialInfo.supply_time_first }}</p>
                                </li>
                                <li>
                                    <!-- <p style="color: #C4FE72; cursor: pointer;" @click="viewMonomer(essentialInfo.eiid)">
                                        单体数量：{{ essentialInfo.monomer_count }}个
                                        <i class="iconfont iconshuangyoujiantou-"></i>
                                    </p> -->
                                    <p>单体数量：{{ essentialInfo.monomer_count }}个</p>
                                    <p>建设规模：{{ essentialInfo.construction_scale }}万m³</p>
                                </li>
                            </ul>
                            <button @click="contractDetails(essentialInfo.engineering_number)">
                                合同详情<i class="iconfont iconshuangyoujiantou-"></i>
                            </button>
                        </div>
                        <div class="el_right">
                            <div class="projectLocation boxShadow">
                                <h4 class="header">
                                    项目位置
                                </h4>
                                <div class="chart" id="stationChart"></div>
                                <div class="map">
                                    <miniMap :essential-info="essentialInfo"></miniMap>
                                </div>
                            </div>
                            <div class="imageProgress boxShadow">
                                <h4 class="header">
                                    <span class="el_left">形象进度</span>
                                    <!-- <button class="el_right" @click="projectProgress">
                                        更多
                                        <i class="iconfont iconshuangyoujiantou-"></i>
                                    </button> -->
                                </h4>
                                <ul :class="{'noData': enclosureData.length<1}">
                                    <template>
                                        <li>
                                            <iframe src="" frameborder="0"></iframe>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="projectProgress boxBorder">
                        <div class="timeAxis boxShadow">
                            <div class="title">
                                <h4 class="header el_left">
                                    时间轴
                                </h4>
                                <template v-for="(item,index) in timeAxisOption">
                                    <button :key="index" :class="{'active':timeAxiSelect == index}" @click="timeAxisFilteringData(item.code, index)">
                                        {{ item.name }}
                                    </button>
                                </template>
                                <el-date-picker
                                    v-model="timeSlot"
                                    type="daterange"
                                    range-separator="——"
                                    value-format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    @change="selectTimeSlot"
                                >
                                </el-date-picker>
                            </div>
                            <ul>
                                <li>
                                    <span>订单个数(个)：</span>
                                    <strong>{{ timelineData.order_count }}</strong>
                                </li>
                                <li>
                                    <span>发货车次(次)：</span>
                                    <strong>{{ timelineData.delivery_count }}</strong>
                                </li>
                                <li>
                                    <span>销售方量(m³)：</span>
                                    <strong>{{ timelineData.sale_quantity }}</strong>
                                </li>
                                <li>
                                    <span>发货方量(m³)：</span>
                                    <strong>{{ timelineData.delivery_quantity }}</strong>
                                </li>
                                <li>
                                    <span>签收方量(m³)：</span>
                                    <strong>{{ timelineData.sign_quantity }}</strong>
                                </li>
                                <li>
                                    <span>退砼方量(m³)：</span>
                                    <strong>{{ timelineData.back_quantity }}</strong>
                                </li>
                                <li>
                                    <span>签收率：</span>
                                    <strong>{{ timelineData.sign_percent }}%</strong>
                                </li>
                            </ul>
                        </div>
                        <div class="charts">
                            <div class="materialRequestTime boxShadow">
                                <h4 class="header">
                                    要料时间
                                </h4>
                                <div class="chart" id="materialRequestTimeChart"></div>
                            </div>
                            <div class="constructionProgress boxShadow">
                                <h4 class="header">
                                    施工进度
                                </h4>
                                <div class="chart" id="constructionProgressChart"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box_t_r el_right boxBorder">
                    <div class="amount boxShadow">
                        <div class="title">
                            <i></i>
                            <p>
                                <strong>已供方量(方)：</strong>
                                <span>{{ volumeData.used_capacity }}</span>
                            </p>
                        </div>
                        <div class="chart" id="amountChart"></div>
                        <ul>
                            <li>
                                <h5>已供方量(方)</h5>
                                <span>{{ volumeData.used_capacity }}</span>
                            </li>
                            <li>
                                <h5>预计方量(方)</h5>
                                <span>{{ volumeData.projected_capacity }}</span>
                            </li>
                            <li>
                                <h5>预计剩余(方)</h5>
                                <span>{{ volumeData.projected_surplus_capacity }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="price boxShadow">
                        <div class="title">
                            <i></i>
                            <p>
                                <strong>C30当前价：</strong>
                                <span>{{ priceData.c30_price }}</span>
                            </p>
                        </div>
                        <ul>
                            <li>
                                <h5 class="iconfont iconqian">
                                    工程均价(元)
                                </h5>
                                <p>{{ priceData.engineering_price }}</p>
                            </li>
                            <li :class="{'low': priceData.compared_enterprise_price < 0}">
                                <h5 class="iconfont iconqian">
                                    较企业均价
                                </h5>
                                <p>
                                    {{ priceData.compared_enterprise_price }}
                                    <span>%</span>
                                    <i class="iconfont iconjiang-t"></i>
                                    <i class="iconfont iconjiang"></i>
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div class="consumption boxShadow">
                        <h4 class="header">
                            <span class="el_left">工程单方消耗</span>
                            <span class="el_right">单位(kg/方)</span>
                        </h4>
                        <div class="chart" :class="{'noData':isConsumeData}" id="consumptionChart"></div>
                    </div>
                </div>
            </div>
            <div class="box_b boxBorder boxShadow">
                <h4 class="header">
                    全部工程
                </h4>
                <div class="charts" :class="{'noData': projectBidSection.length < 1}">
                    <template v-for="(item,index) in projectBidSection">
                        <div :key="index">
                            <div class="chartBox">
                                <div class="el_left chart" :class="'projectBidSection' + index"></div>
                                <ul class="el_left">
                                    <li>
                                        <h5>预计方量</h5>
                                        <span>{{ item.projected_capacity }}</span>
                                    </li>
                                    <li>
                                        <h5>已供方量(方)</h5>
                                        <span>{{ item.delivery_total_quantity }}</span>
                                    </li>
                                </ul>
                            </div>
                            <ul class="list">
                                <li>
                                    <h5>工程名称</h5>
                                    <p>
                                        {{ item.engineering_name }}
                                    </p>
                                </li>
                                <li>
                                    <h5>施工单位</h5>
                                    <p>
                                        {{ item.construction_enterprise }}
                                    </p>
                                </li>
                                <li @click="openContractLifeCycle(item)">
                                    <h5>合同编号</h5>
                                    <p style="color:#C4FE72; cursor: pointer;">
                                        {{ item.contract_number }}
                                    </p>
                                </li>
                                <li>
                                    <h5>签约时间</h5>
                                    <p>
                                        {{ item.take_effect_time }}
                                    </p>
                                </li>
                                <li>
                                    <h5>供货站点</h5>
                                    <p>
                                        {{ item.supply_site_name }}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// 地图组件
import miniMap from '@/views/lifeCycle/miniMap';

import {
    waterPolo,
    ringDiagram,
    drawBar,
    scatterPlot,
    multiStoreyLine,
    ciYun,
} from './../../assets/js/charts';
import 'echarts-liquidfill';

export default {
    // 工程生命周期弹出层
    name: 'engineering-life-cycle',
    components: {
        miniMap,
    },
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            // 当前工程编号
            engineeringNumber: '',
            // 租户id
            zuHId: '',
            // 月天数
            monthDays: 0,
            // 时间轴选项
            timeAxisOption: [{
                name: '全部',
                code: '0',
            }, {
                name: '本月',
                code: '1',
            }, {
                name: '本周',
                code: '2',
            }],
            // 时间段
            timeSlot: [],
            // 时间轴选中下标
            timeAxiSelect: 0,
            // 工程基本信息
            essentialInfo: {},
            // 形象进度图片
            enclosureData: [],
            // 方量数据
            volumeData: {},
            // 价格数据
            priceData: {},
            // 工程单方消耗数据
            isConsumeData: false,
            // 时间轴数据
            timelineData: {},
            // 工程名称
            projectBidSection: {},
        };
    },
    created() {
        this.engineeringNumber = this.extr.code.engineeringNumber;
        this.zuHId = this.extr.code.zuHId;
        // 月天数
        this.monthDays = this.mGetDate(this.getNowFormatDate());
        // 初始化页面
        if (this.engineeringNumber && this.engineeringNumber !== '') {
            this.init();
        }

    },
    mounted() {},
    updated() {},
    methods: {
        // 格式化当前日期
        getNowFormatDate(data) {
            const date = new Date(data);
            const seperator1 = '-';
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const strDate = date.getDate();
            const currentdate = year + seperator1 + month + seperator1 + strDate;
            return currentdate;
        },
        // 获取当前周一周天日期
        getWeekStr() {
            const now = new Date();
            const nowTime = now.getTime();
            const day = now.getDay();
            const oneDayTime = 24 * 60 * 60 * 1000;
            const MondayTime = new Date(nowTime - (day - 1) * oneDayTime);// 显示周一
            const SundayTime = new Date(nowTime + (7 - day) * oneDayTime);// 显示周日

            const newtime = [this.getNowFormatDate(MondayTime), this.getNowFormatDate(SundayTime)];
            return newtime;
        },
        // 获取本月第一天
        currentMonthFirst() {
            const date = new Date();
            date.setDate(1);
            return this.getNowFormatDate(date);
        },
        // 获取本月最后一天
        currentMonthLast() {
            const date = new Date();
            let currentMonth = date.getMonth();
            const nextMonth = ++currentMonth;
            const nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
            const oneDay = 1000 * 60 * 60 * 24;
            const newTime = new Date(nextMonthFirstDay - oneDay);
            return this.getNowFormatDate(newTime);
        },
        // 获取当前月天数
        mGetDate() {
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const d = new Date(year, month, 0);
            return d.getDate();
        },
        // 初始化页面
        async init() {
            this.loading = true;
            // 工程基本信息
            this.essentialInfo = {};
            // 方量数据
            this.volumeData = {};
            // 价格数据
            this.priceData = {};
            // 时间轴数据
            this.timelineData = {};
            // 工程名称
            this.projectBidSection = {};

            // 获取工程基本信息数据
            await this.getEssentialInfo();
            // 获取获取供应方量数据
            await this.getVolumeData();
            // 获取价格数据
            await this.getPriceData();
            // 获取时间轴数据
            await this.getTimelineData();
            // 获取工程单方消耗数据
            await this.getConsumeData();
            // 获取工程名称数据
            await this.getProjectBidSection();
            this.loading = false;
        },
        // 时间轴过滤时间段数据
        timeAxisFilteringData(data, i) {
            // 置空时间段
            this.timeSlot = [];
            // 时间轴数据
            this.timelineData = {};
            let newUrl = '';
            this.timeAxiSelect = i;
            if (data === '0') {
                newUrl = `${this.engineeringNumber}?zu_h_id=${this.zuHId}`;
            } else if (data === '1') {
                const monthFirst = this.currentMonthFirst();
                const monthLast = this.currentMonthLast();
                newUrl = `${this.engineeringNumber}?start=${monthFirst}&end=${monthLast}&zu_h_id=${this.zuHId}`;
            } else if (data === '2') {
                const newTime = this.getWeekStr();
                newUrl = `${this.engineeringNumber}?start=${newTime[0]}&end=${newTime[1]}&zu_h_id=${this.zuHId}`;
            }
            // 获取时间轴数据
            this.getTimelineData(newUrl);
        },
        // 选择时间段
        selectTimeSlot() {
            // 时间轴选中下标
            this.timeAxiSelect = null;
            const newUrl = `${this.engineeringNumber}?start=${this.timeSlot[0]}&end=${this.timeSlot[1]}&zu_h_id=${this.zuHId}`;
            // 获取时间轴数据
            this.getTimelineData(newUrl);
        },
        // 获取工程基本信息数据
        getEssentialInfo() {
            this.$axios
                .get(`/interfaceApi/sale/engineering_life_cycle/engineering_info/${this.engineeringNumber}?zu_h_id=${this.zuHId}`)
                .then(res => {
                    if (res) {
                        // 工程基本信息
                        this.essentialInfo = res;

                        const chartData = {
                            title: '供货站点：',
                            data: res.supply_site_names,
                        };

                        ciYun('stationChart', chartData);
                        if (res.visual_progress_images && res.visual_progress_images !== '') {
                            const newData = res.visual_progress_images.split(',');
                            newData.forEach(item => {
                                // item = this.FILE.FILE_BASE_URL() + 'file/preview/' + item;
                                // this.enclosureData.push(item);
                                this.getFileDetailFun(item, 'enclosureData');
                            });
                        }
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        getFileDetailFun(id, key) {
            this.$axios
                .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + id)
                .then(res => {
                    this[key].push(res.preview_url);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取供应方量数据
        getVolumeData() {
            this.$axios
                .get(`/interfaceApi/sale/engineering_life_cycle/engineering_quantity/${this.engineeringNumber}?zu_h_id=${this.zuHId}`)
                .then(res => {
                    if (res) {
                        // 工程基本信息
                        this.volumeData = res;
                        const chartData = {
                            title: '',
                            data: [],
                        };
                        if (res.structure_mark && res.structure_mark.length > 0) {
                            chartData.title = '方量';
                            res.structure_mark.forEach((item, index) => {
                                chartData.data.push({
                                    name: item,
                                    value: res.structure_quantity[index],
                                });
                            });
                        } else {
                            chartData.title = '暂无数据';
                        }
                        ringDiagram('amountChart', chartData);
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取价格数据
        getPriceData() {
            this.$axios
                .get(`/interfaceApi/sale/engineering_life_cycle/engineering_price/${this.engineeringNumber}?zu_h_id=${this.zuHId}`)
                .then(res => {
                    if (res) {
                        this.priceData = res;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取时间轴数据
        getTimelineData(url) {
            if (!url) {
                url = `${this.engineeringNumber}?zu_h_id=${this.zuHId}`;
            }
            this.$axios
                .get('/interfaceApi/sale/engineering_life_cycle/engineering_timer_shaft/' + url)
                .then(res => {
                    if (res) {
                        this.timelineData = res;
                        if (res.scatter_punchCard.length > 0) {
                            scatterPlot('materialRequestTimeChart', res.scatter_punchCard);
                        }
                        if (res.is_fj) {
                            const chartData = {
                                xAxis: {
                                    data: [],
                                },
                                legend: ['地基与基础', '主体结构', '其他', '销售方量'],
                                yAxis: {
                                    data: ['(方)'],
                                },
                                series: {
                                    data: [ {
                                        type: 'bar',
                                        name: '',
                                        data: [],
                                    }, {
                                        type: 'line',
                                        name: '销售方量',
                                        data: [],
                                    }],
                                },
                            };
                            if (res.sgjd_dt) {
                                chartData.xAxis.data = res.sgjd_dt;
                            }
                            if (res.sgjd_dt_jg) {
                                chartData.series.data[0].data = res.sgjd_dt_jg;
                            }
                            if (res.sgjd_xsfl) {
                                chartData.series.data[1].data = res.sgjd_xsfl;
                            }
                            multiStoreyLine('constructionProgressChart', chartData);
                        } else {
                            const chartData = {
                                xAxis: {
                                    data: [],
                                },
                                yAxis: {
                                    name: '(方)',
                                    nameTextStyle: [0, 0, -7, 0],
                                },
                                series: [
                                    {
                                        barWidth: 24,
                                        color: [ '#20E5FF', '#2F7EF2'],
                                        data: [],
                                    },
                                ],
                            };
                            chartData.xAxis.data.push(...res.sgjd_sj);
                            chartData.series[0].data = res.sgjd_xsfl;
                            drawBar('constructionProgressChart', chartData);
                        }
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取工程单方消耗数据
        getConsumeData() {
            this.$axios
                .get(`/interfaceApi/sale/engineering_life_cycle/consumption/${this.engineeringNumber}?zu_h_id=${this.zuHId}`)
                .then(res => {
                    if (res) {
                        this.consumeData = res;
                        const chartData = {
                            xAxis: {
                                data: [],
                            },
                            yAxis: {
                                name: '',
                                nameTextStyle: [0, 0, -7, 0],
                            },
                            series: [
                                {
                                    name: '工程单方消耗',
                                    barWidth: 24,
                                    color: [ '#20E5FF', '#2F7EF2'],
                                    data: [],
                                }, {
                                    name: '企业消耗',
                                    barWidth: 24,
                                    color: ['#FFC452', '#FB7D3C' ],
                                    data: [],
                                },
                            ],
                        };
                        if (res.material_category_name.length > 0) {
                            chartData.xAxis.data = res.material_category_name;
                        }
                        if (res.engineering_consumption.length > 0) {
                            chartData.series[0].data = res.engineering_consumption;
                        }
                        if (res.enterprise_consumption.length > 0) {
                            chartData.series[1].data = res.enterprise_consumption;
                        }
                        if (res.engineering_consumption.length < 1 || res.enterprise_consumption.length < 1) {
                            this.isConsumeData = true;
                        } else {
                            // 工程单方消耗
                            drawBar('consumptionChart', chartData);
                        }

                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取工程名称数据
        getProjectBidSection() {
            this.$axios
                .get(`/interfaceApi/sale/engineering_life_cycle/engineering_section/${this.engineeringNumber}?zu_h_id=${this.zuHId}`)
                .then(res => {
                    if (res) {
                        this.projectBidSection = res;
                        const color = ['#05A9F4', '#FFB205', '#78D563', '#FA8436'];
                        this.$nextTick(function () {
                            this.projectBidSection.forEach((item, index) => {
                                this.$('.projectBidSection' + index).html('');
                                const newDom = `<div style="width:1.2rem; height:1.2rem;" id="projectBidSectionChart${index}"></div>`;
                                this.$('.projectBidSection' + index).append(newDom);

                                const chartData = {
                                    color: [color[index] || color[0]],
                                    data: [item.delivery_percent],
                                };
                                waterPolo(`projectBidSectionChart${index}`, chartData);
                            });
                        });
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 查看单体列表
        viewMonomer(eiid) {
            this.$toast({
                title: true,
                type: 'sideslip',
                width: '15.4rem',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'engineering_monomer_manage',
                        ViewCode: 'view',
                        QueryString: 'engineering_id=' + eiid,
                    },
                },
            });
        },
        // 合同详情
        contractDetails(engineering_number) {
            this.$toast({
                title: true,
                type: 'eject',
                width: '13.4rem',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'project_contractView',
                        QueryString: 'project_number=' + engineering_number,
                    },
                },
            });
        },
        // 打开工程进度
        projectProgress() {
            this.$toast({
                title: true,
                text: '工程进度',
                type: 'eject',
                width: '80%',
                height: '90%',
                t_url: 'salesBusiness/projectInfoManagement/projectInfo/projectProgress',
                viewPosition: 'view',
                extr: {
                    closeBtn: {},
                    engineering_id: this.essentialInfo.eiid,
                },
            });
        },
        // 打开合同生命周期
        openContractLifeCycle(data) {
            this.$toast({
                title: false,
                type: 'eject',
                theme: 'black',
                width: '16.8rem',
                height: '90%',
                style: {},
                t_url: 'lifeCycle/contractLifeCycle',
                extr: {
                    code: {
                        contract_number: data.contract_number,
                        zuHId: this.zuHId,
                    },
                },
            });
        },
    },
    filter: {},
    computed: {},
};
</script>

<style lang="stylus">
.engineeringLifeCycle
    height 100%
    padding 0 0.15rem 0.15rem
    .boxBorder
        border 1px solid #05A9F4
        background rgba(9, 18, 31, 0.3);
    .boxShadow
        padding 0.2rem
        box-shadow inset 1px 1px 20px 0px rgba(5, 169, 244, 0.5);
    .header
        overflow hidden
        font-size 0.18rem
        line-height 0.2rem
        height 0.2rem
        color rgba(255,255,255,0.8)
        padding-left 0.1rem
        margin-bottom 0.15rem
        background-image none
        border-left 0.06rem solid rgba(255,255,255,0.8)
    .noData
        position relative
        &:after
            content '暂无数据'
            font-size 0.5rem
            font-weight 600
            color rgba(255,255,255,0.3)
            position absolute
            top 50%
            left 50%
            transform translate(-50%, -50%)
    .lifeCycleHeader
        height 0.86rem
        background url(images/light.png) center bottom no-repeat
        .title
            font-size 0.2rem
            text-align center
            font-family Source Han Sans CN
            font-weight bold
            color rgba(255, 255, 255, 1)
            line-height 0.56rem
            background linear-gradient(0deg, rgba(108, 151, 216, 1) 0%, rgba(255, 255, 255, 1) 100%)
            -webkit-background-clip text
            -webkit-text-fill-color transparent
    .lifeCycleContent
        height calc(100% - 0.86rem)
        overflow-y auto
    .box_t
        height 9.74rem
        padding-bottom 0.1rem
        .box_t_l
            width 11.75rem
            height 100%
            .basicEngineeringInfo
                height 3.14rem
                margin-bottom 0.1rem
                >div
                    width calc(50% - 0.01rem)
                .engineeringInfo
                    height 100%
                    ul
                        li
                            display flex
                            line-height 0.34rem
                            p
                                flex 1
                                font-size 0.16rem
                                color rgba(255,255,255,0.8)
                                overflow hidden
                                text-overflow ellipsis
                                white-space nowrap
                    button
                        width 1.1rem
                        height 0.3rem
                        font-size 0.16rem
                        color #C4FE72
                        cursor pointer
                        background rgba(196, 254, 114, 0.1)
                        border 1px solid rgba(196, 254, 114, 0.5)
                .el_right
                    height 100%
                .projectLocation
                    height 1.54rem
                    margin-bottom 0.02rem
                    position relative
                    .header
                        margin-bottom 0.1rem
                    .chart
                        width 2.4rem
                        height calc(100% - 0.3rem)
                    .map
                        position absolute
                        top 0.16rem
                        right 0.16rem
                        width 2.4rem
                        height 1.2rem
                        border 1px solid #0E3C6E
                .imageProgress
                    height calc(100% - 1.56rem)
                    .header
                        button
                            width 0.6rem
                            height 0.24rem
                            font-size 0.14rem
                            color #C4FE72
                            cursor pointer
                            background rgba(196, 254, 114, 0.1)
                            border 1px solid rgba(196, 254, 114, 0.5)
                    ul
                        overflow hidden
                        li
                            float left
                            width 0.9rem
                            height 0.8rem
                            margin-right 0.2rem
                            &:last-child
                                margin-right 0
                            iframe
                                width 100%
                                height 100%
            .projectProgress
                height 6.4rem
                .timeAxis
                    height 1.75rem
                    margin-bottom 0.02rem
                    .title
                        overflow hidden
                        .header
                            margin-top 0.05rem
                            margin-bottom 0
                        button
                            float left
                            width 60px
                            height 30px
                            margin-left 0.2rem
                            background rgba(85, 136, 241, 0.1)
                            border 1px solid #20B0FB
                            font-size 0.16rem
                            color #20B0FB
                            &.active
                                box-shadow inset 0px 1px 20px 0px #20B0FB
                        .el-date-editor
                            width 3rem !important
                            margin-left 0.2rem
                            padding 0 0.1rem
                            height 0.3rem
                            border none
                            border-radius 0
                            background #051B36
                            box-shadow inset 0px 1px 16px 0px #20B0FB
                            .el-range-input
                                background rgba(255,255,255,0)
                                border none
                                color rgba(255,255,255,0.8)
                                font-size 0.14rem
                            .el-range-separator
                                color rgba(255,255,255,0.5)
                            .el-input__icon
                                line-height 0.28rem
                    ul
                        overflow hidden
                        display flex
                        margin-top 0.2rem
                        li
                            flex 1
                            margin-right 0.3rem
                            padding-left 0.1rem
                            &:last-child
                                margin-right 0
                            span,strong
                                display block
                            span
                                font-size 0.16rem
                                line-height 0.3rem
                                margin-bottom 0.1rem
                                color rgba(255,255,255,0.8)
                            strong
                                font-weight 400
                                font-size 0.36rem
                                line-height 0.3rem
                                font-family technology
                                color #FA8436
                .charts
                    height calc(100% - 1.77rem)
                    >div
                        float left
                        width calc(50% - 0.01rem)
                        height 100%
                    .materialRequestTime
                        margin-right 0.02rem
                        .chart
                            height calc(100% - 0.35rem)
                    .constructionProgress
                        .chart
                            height calc(100% - 0.35rem)
        .box_t_r
            width 4.55rem
            height 100%
            .amount
                height 3.9rem
                margin-bottom 0.02rem
                .title
                    height 0.56rem
                    width 100%
                    overflow hidden
                    i,p
                        float left
                    i
                        width 0.54rem
                        height 0.48rem
                        background url(images/icon_l.png) no-repeat
                        background-size 100%
                        position relative
                        z-z-index 9
                    p
                        width calc(100% - 0.4rem)
                        height 0.4rem
                        margin 0.04rem 0 0.04rem -0.16rem
                        background rgba(80, 219, 84, 0.1)
                        text-align center
                        line-height 0.4rem
                        strong
                            font-size 0.18rem
                            color rgba(255,255,255,0.8)
                            vertical-align typeof
                            font-weight 400
                        span
                            font-family technology
                            font-size 0.36rem
                            color #50DB54
                            vertical-align top
                .chart
                    height calc(100% - 1.15rem)
                ul
                    overflow hidden
                    li
                        width calc((100% - 0.3rem) /3)
                        height 0.6rem
                        margin-right 0.15rem
                        text-align center
                        float left
                        padding 0.06rem
                        &:last-child
                            margin-right 0
                        h5
                            font-size 0.14rem
                            color #FFFFFF
                        span
                            font-size 0.24rem
                            line-height 0.3rem
                            color #20B0FB
                            font-family technology
                        &:nth-of-type(1)
                            box-shadow inset 0px 1px 20px 0px #20B0FB
                            span
                                color #05A9F4
                        &:nth-of-type(2)
                            box-shadow inset 0px 1px 20px 0px #FA8436
                            span
                                color #FA8436
                        &:nth-of-type(3)
                            box-shadow inset 0px 1px 20px 0px #78D563
                            span
                                color #78D563
            .price
                height 1.9rem
                margin-bottom 0.02rem
                .title
                    height 0.56rem
                    width 100%
                    overflow hidden
                    i,p
                        float left
                    i
                        width 0.54rem
                        height 0.48rem
                        background url(images/icon_j.png) no-repeat
                        background-size 100%
                        position relative
                        z-index 9
                    p
                        width calc(100% - 0.4rem)
                        height 0.4rem
                        margin 0.04rem 0 0.04rem -0.16rem
                        background rgba(255, 178, 5, 0.1)
                        text-align center
                        line-height 0.4rem
                        strong
                            font-size 0.18rem
                            color rgba(255,255,255,0.8)
                            vertical-align typeof
                            font-weight 400
                        span
                            font-family technology
                            font-size 0.36rem
                            color #FA8436
                            vertical-align top
                ul
                    margin-top 0.1rem
                    text-align center
                    li
                        width 1.3rem
                        display inline-block
                        text-align center
                        margin 0 0.3rem
                        vertical-align top
                        h5
                            font-size 0.16rem
                            color rgba(255,255,255,0.8)
                            margin-bottom 0.15rem
                        p
                            width 1.3rem
                            height 0.5rem
                            font-size 0.3rem
                            line-height 0.5rem
                            font-family technology
                        &:nth-of-type(1)
                            h5
                                &:before
                                    color #20B0FB
                            p
                                color #05A9F4
                                box-shadow inset 0px 1px 20px 0px #20B0FB
                        &:nth-of-type(2)
                            h5
                                &:before
                                    color #FC6B51
                            p
                                color #FC6B51
                                box-shadow inset 0px 1px 20px 0px #ED664F
                                span
                                    font-size 0.26rem
                                i
                                    font-size 0.24rem
                                    vertical-align top
                                    &.iconjiang
                                        display none
                            &.low
                                h5
                                    &:before
                                        color #78D563
                                p
                                    color #78D563
                                    box-shadow inset 0px 1px 20px 0px #78D563
                                    i
                                        font-size 0.24rem
                                        vertical-align top
                                        &.iconjiang-t
                                            display none
                                        &.iconjiang
                                            display inline-block
            .consumption
                height calc(100% - 5.84rem)
                .chart
                    height calc(100% - 0.35rem)
    .box_b
        min-height 3.77rem
        .charts
            height 3rem
            overflow hidden
            >div
                width 25%
                height 100%
                float left
                padding 0 0.15rem
                border-right 2px dashed rgba(255,255,255,0.5)
                &:first-child
                    padding-left 0
                &:last-child
                    border none
                    padding-right 0
                .chartBox
                    height 1.2rem
                    margin-bottom 0.15rem
                    .chart
                        width 1.2rem
                        height 1.2rem
                        margin-right 0.15rem
                    ul
                        overflow hidden
                        width calc(100% - 1.4rem)
                        margin-top 0.3rem
                        li
                            width calc((100% - 0.15rem) /2)
                            height 0.6rem
                            margin-right 0.15rem
                            text-align center
                            float left
                            padding 0.06rem
                            &:last-child
                                margin-right 0
                            h5
                                font-size 0.14rem
                                color #FFFFFF
                            span
                                font-size 0.24rem
                                line-height 0.3rem
                                color #20B0FB
                                font-family technology
                            &:nth-of-type(1)
                                box-shadow inset 0px 1px 20px 0px #20B0FB
                                span
                                    color #05A9F4
                            &:nth-of-type(2)
                                box-shadow inset 0px 1px 20px 0px #FA8436
                                span
                                    color #FA8436
            .list
                li
                    overflow hidden
                    margin-bottom 0.02rem
                    h5,p
                        float left
                        font-size 0.16rem
                        line-height 0.31rem
                        height 0.31rem
                        box-shadow inset 0px 1px 10px 0px #20B0FB
                    h5
                        margin-right 0.02rem
                        width 0.8rem
                        text-align center
                        color rgba(255,255,255,0.8)
                    p
                        width calc(100% - 0.82rem)
                        padding-left 0.1rem
                        color #20B0FB
</style>
